AOS.init();

try {
   const heroElement = document.querySelector('.hero-section');
   const backgroundImage = heroElement.dataset.backgroundImage;
   heroElement.style.backgroundImage = `url(${backgroundImage})`;
} catch (error) {

}

document.addEventListener('DOMContentLoaded', function () {
   var navLinks = document.querySelectorAll('#menu-group');

   navLinks.forEach(function (navLink) {

      if (window.innerWidth < 600) {
         navLink.addEventListener('click', function () {
            this.classList.toggle('show');
         })
      } else {

         navLink.addEventListener('mouseenter', function () {
            this.classList.add('show');
         });
         navLink.addEventListener('mouseleave', function () {
            this.classList.remove('show');
         });
      }

   });
});


// accordion
const items = document.querySelectorAll(".accordion button");

function toggleAccordion() {
   const itemToggle = this.getAttribute('aria-expanded');

   for (let i = 0; i < items.length; i++) {
      items[i].setAttribute('aria-expanded', 'false');
   }

   if (itemToggle == 'false') {
      this.setAttribute('aria-expanded', 'true');
   }
}

items.forEach(item => item.addEventListener('click', toggleAccordion));

const articles = document.querySelectorAll('.article')

articles.forEach(article => {
   if (window.innerWidth <= 576) {
      console.log('en mobile')
      if (article.classList.contains('article--r')) {
         article.classList.add('article--l');
      }

   } else {
      article.classList.remove('article--l');
   }
})

// Open menu & search pop-up
const menuToggleIcon = document.querySelector('#hamburguer-menu-icon')
const bgMenu = document.querySelector('.modal-bg')

const toggleMenu = () => {
   const mobileMenu = document.querySelector('#nav-menu')
   mobileMenu.classList.toggle('activated')
   bgMenu.classList.toggle('activated')

   document.querySelector('.container').classList.toggle('fixed')

}

menuToggleIcon.addEventListener('click', toggleMenu)


// Obtener referencia al elemento HTML
window.addEventListener('DOMContentLoaded', () => {
   const imgElement = document.querySelector('#image-vision');
   const imgHeroHome = document.querySelector('.hero');

   function changeImageBasedOnScreenWidth() {
      if (window.innerWidth < 768) {
         imgHeroHome.style.backgroundImage = 'url("/dist/images/hero2-mobile.png")';
         imgElement.src = '/dist/images/background-sky-mobile.png';
      } else {
         imgHeroHome.style.backgroundImage = 'url("/dist/images/hero2.png")';
         imgElement.src = '/dist/images/background-sky.png';
      }
   }

   // Ejecutar la función al cargar la página
   changeImageBasedOnScreenWidth();

   // Ejecutar la función cuando se cambie el tamaño de la pantalla
   window.addEventListener('resize', changeImageBasedOnScreenWidth);
});